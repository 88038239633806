//background-image: url("assets/images/avatars.jpg");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

html,
body,
#root {
  height: 100%;
  font-size: 10px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  background: linear-gradient(to bottom, #13547a 0%, #80d0c7 100%);
  // enable this when going live
  // overflow: hidden;
}

#root.ios {
  padding-top: 2rem;
  > #modals-root {
    > .modals,
    > .full-screen-modals {
      padding-top: 2rem;
    }
  }
}

.cards-app {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__amb-placement {
    height: 6rem;
    width: 100%;
  }

  &__content {
    flex: 1;
    position: relative;
  }
}

.full-screen-modals {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11;
}

.modals {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.55);

  .modal {
    //border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 10px;

    &__close-icon {
      width: 22px;
      position: absolute;
      right: -10px;
      top: -10px;
    }

    &__title {
      &-wrapper {
        display: flex;
        justify-content: center;
        position: absolute;
        margin-left: auto;
        top: -16px;
        margin-right: auto;
        width: 100%;
        z-index: 2;
        left: 0;
      }

      position: relative;
      background: black;
      padding: 4px 15px;
      font-weight: bold;
      border-radius: 6px;
      color: #2196f3;
      font-size: 1.2rem;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #2196f3;
    }

    &__input {
      background: rgba(255, 255, 255, 0.2);
      height: auto;
      padding: 10px 10px;
      box-shadow: none;
      border-radius: 0;
      /* font-size: 36px; */
      /* color: #fff; */
      outline: 0;
      width: 100%;
      border: 1px solid #00000054;
      color: #555;
      background-color: #fff;
      width: 100%;
      box-sizing: border-box;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
      padding-top: 20px;
    }

    &__action {
      background: #2196f3;
      color: white;
      font-weight: bold;
      border: 0px;
      padding: 5px 20px;
      outline: 0;
      border-radius: 2px;
      font-size: 1.2rem;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding-box;
      background-clip: padding-box;

      &--secondary {
        background: #8b9298;
      }

      &:disabled {
        box-shadow: none;
        background: #8b8e88;
        color: white;
      }
    }
  }
}

.partial-loader {
  border: 3px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 3px solid #2196f3;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  &__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-img {
  width: 80px;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.player {
  position: relative;
  height: fit-content;

  &__wrapper {
    display: flex;
    flex-direction: row;
    padding: 10px;
    position: relative;
  }

  &__menu {
    margin: 10px;
  }

  &__box {
    display: flex;
    flex-direction: column;
  }

  &__missed-chance {
    width: 1.26rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    &-item {
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 100px;
      background: white;
      margin-bottom: 0.4rem;

      &--red {
        background: #fe2400;
      }
    }
  }

  &__inner {
    width: 75px;
    height: 75px;
    // border: 3px solid rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: flex-end;
    position: relative;
  }

  &__action-icons {
    &-left {
      position: absolute;
      top: -10px;
      left: -10px;
      z-index: 1;
      display: flex;
      flex-direction: column;
    }

    &-right {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
      display: flex;
      flex-direction: column;
    }

    &-chat {
      transform: scaleX(-1);
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
      height: 20px;
    }

    &-score {
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
      width: 25px;
    }
  }

  &__game-info {
    background: rgba(0, 0, 0, 0.85);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding-bottom: 4px;
    padding-top: 2px;
  }

  &__name {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
  }

  &__coins {
    position: absolute;
    bottom: 2.05rem;
    right: 0;
    background-color: rgba(0, 0, 0, 0.81);
    padding: 0.25rem 0.4rem;
    color: #f4dd11;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    line-height: 1.1rem;
    font-size: 1.1rem;
  }

  &__background {
    width: 100%;
    height: 100%;
    z-index: -1;
    background: antiquewhite;
    padding: 6px;
  }

  &__details {
    position: relative;
    width: 100%;
    border: 0px;

    &-inner {
      height: 16px;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 1.3rem;
      font-weight: bold;
    }

    &-icon {
      padding-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-result-text {
      text-align: center;
      width: 100%;
      color: white;
    }
  }
  &__cards-length {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
  }
  &__score {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.player-menu {
  .player__details-inner {
    color: white;
    font-weight: normal;
    font-size: 1rem;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.coins {
  color: #f4dd11;
  font-weight: bold;
  font-size: 1.3rem;
  border: 1.1px solid #d9dc11a3;
  border-radius: 5px;
  padding: 3px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  background: #00000059;

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 4px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.settings-icon {
  z-index: 1;
  width: 20px;
  height: 20px;
  img {
    width: 20px;
    height: 20px;
  }
}

.leave-icon {
  width: 20px;
  height: 20px;
  z-index: 1;
  img {
    width: 20px;
    height: 20px;
  }
}
