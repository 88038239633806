.card {
  top: 0px;
  transform-style: preserve-3d;
  width: 80px;
  position: absolute;
  height: 116px;
  pointer-events: all;
  will-change: transform;
  transition-property: transform;

  &.others-card {
    width: auto;
  }

  &.extra-joker-card {
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      &::after {
        content: "Extra Joker";
        position: absolute;
        background: #f4dd11;
        color: black;
        bottom: 0rem;
        font-weight: bold;
        padding: 0.4rem 0rem;
        text-align: center;
        bottom: 27px;
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  &.selected {
    top: -15px;
    .front-side {
      img {
        box-shadow: 0px -1px 4px 1.7px rgba(244, 221, 17, 0.7);
      }
    }
  }

  .front-side {
    position: absolute;
    backface-visibility: hidden;
    transform: rotateY(0deg);
  }

  .back-side {
    position: absolute;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: transform 0.5s linear;
  }

  &.used-card {
    .overlay {
      position: absolute;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.44);
      width: 100%;
    }

    &.is-top {
      .overlay {
        display: none;
      }
    }
  }

  &.new-card {
    .card-img {
      box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.3);
    }
  }

  @-webkit-keyframes border-blink1 {
    from,
    to {
      border: 1px solid white;
    }
    50% {
      border: 1px solid #00000080;
    }
  }
  @keyframes border-blink1 {
    from,
    to {
      border: 1px solid white;
    }
    50% {
      border: 1px solid #00000080;
    }
  }

  @-webkit-keyframes border-blink2 {
    from,
    to {
      border: 1.2px solid white;
    }
    50% {
      border: 1.2px solid #00000080;
    }
  }
  @keyframes border-blink2 {
    from,
    to {
      border: 1.2px solid white;
    }
    50% {
      border: 1.2px solid #00000080;
    }
  }
  &.used-card.is-top.can-pick {
    .front-side {
      .card-img {
        border: 1px solid white;
        -webkit-animation: border-blink2 0.5s step-end infinite;
        animation: border-blink2 0.5s step-end infinite;
        background: white;
      }
    }
    .card-img {
      box-shadow: none;
    }
  }
  &.new-card.is-top.can-pick {
    .back-side {
      .card-img {
        border: 1px solid white;
        -webkit-animation: border-blink1 0.5s step-end infinite;
        animation: border-blink1 0.5s step-end infinite;
        background: white;
      }
    }
    .card-img {
      box-shadow: none;
    }
  }
}

.game {
  height: 100%;
  position: relative;
  z-index: 0;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    background-image: linear-gradient(to bottom, #13547a 0%, #80d0c7 100%);

    &-img {
      background: url("../../assets/images/card-suits.png");
      background-size: 40px;
      opacity: 0.16;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    $self: &;
    height: 100%;

    @at-root .ios #{$self} {
      height: 100%;
    }

    display: flex;
    flex-direction: column;
    z-index: 0;
    position: relative;

    .cards-play-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .virtual-bounds {
      height: 100%;
      display: flex;
      flex-direction: column;

      .game__actions {
        height: 2rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0.2rem 0.2rem 0;
      }

      .players {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        scroll-behavior: smooth;
        overflow: auto;

        &__wrapper {
          flex: 2;
          min-height: 11.5rem;
          position: relative;
        }

        &__show-all-players {
          display: grid;
          position: absolute;
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

          .player__details-inner {
            background: white;
          }

          .player__name {
            color: black;
          }

          .player__wrapper {
            background: rgba(0, 0, 0, 0.76);
            z-index: 1000;
          }
        }

        &__others {
          pointer-events: none;

          .player {
            pointer-events: all;
          }

          &--1 {
            justify-content: space-around;
          }
        }
      }
      .in-play {
        flex: 5;
        display: flex;
        flex-direction: row;

        .used-deck {
          flex: 1;
        }
        .new-deck {
          flex: none;
          width: calc(11rem + 4rem);
        }
      }
      .your-cards {
        flex: 3;
        position: relative;
        min-height: 150px;
      }
    }
  }

  .your-cards {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .close-button {
    border: none;
    color: black;
    text-decoration: none;
    font-size: 12px;
    box-sizing: border-box;
    line-height: 12px;
    display: flex;
    align-items: center;
    background: #f4dd11;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    font-weight: bold;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    width: 7.5rem;
    margin-right: 1rem;
    position: relative;
    text-align: center;
    outline: 0;
    display: flex;
    justify-content: center;

    img {
      transform: rotate(90deg);
      width: 12px;
      margin: 0px 3px;
    }

    .min_points_to_close {
      background: rgba(0, 0, 0, 0.6);
      padding: 0.2rem 0.4rem;
      color: white;
      text-align: center;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50px;
      font-size: 1rem;
      width: 16px;
      height: 16px;
    }

    &:disabled {
      box-shadow: none;
      background: #8b8e88;
      color: white;
    }
  }

  .action-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    justify-content: flex-end;
  }
}

.ribbon {
  $color-ribbon: #2196f3;
  position: absolute;
  width: 80px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 65px;
  left: 7px;

  &.Won {
    $color-ribbon: #2196f3;
    .ribbon__text {
      background-color: $color-ribbon;
    }
    $color-ribbon: darken($color-ribbon, 15);

    &:before {
      border-color: $color-ribbon $color-ribbon $color-ribbon transparent;
    }

    &:after {
      border-color: $color-ribbon transparent $color-ribbon $color-ribbon;
    }
  }

  &.Penalty {
    $color-ribbon: #d63447;
    .ribbon__text {
      background-color: $color-ribbon;
    }
    $color-ribbon: darken($color-ribbon, 25);

    &:before {
      border-color: $color-ribbon $color-ribbon $color-ribbon transparent;
    }

    &:after {
      border-color: $color-ribbon transparent $color-ribbon $color-ribbon;
    }
  }

  &__text {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  &:before,
  &:after {
    top: 3px;
    z-index: -10;
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-style: solid;
    border-width: 0;
  }

  &:before {
    left: -14px;
    border-width: 10px;
  }
  &:after {
    right: -14px;
    border-width: 10px;
  }
}
